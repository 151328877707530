import audiok from '../api/audio';

export function Audio(){
    const coverSrc = "/images/refimages/albums/";
    return (
        <div>
            <h2 className="mt-2 text-secondary display-3 text-center fw-bolder">- HANGANYAGOK -</h2>
            <div className="mt-2 ml-5 mr-5">
                {
                    audiok.map((item)=>(
                        
                        <div key={item.id} className="m-3 p-2 border border-secondary border-5 bg-white text-secondary rounded-3 row">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                <iframe width="100%" height="450" scrolling="no" frameBorder="no" allow="autoplay" src={item.sclink}></iframe>
                                </div>
                                <div className="col-12 col-md-6">
                                    <h3>{item.title}</h3>
                                    <p>
                                        <strong>A megjelenés éve:</strong> {item.date}<br/>
                                        <strong>Szerző:</strong> {item.author}<br/>
                                        <strong>Közreműködtek:</strong><br/>
                                        {item.members.map((member)=>(
                                            <span key={member.id}>&#9734; {member.name}: {member.role}<br/></span>
                                        ))}
                                    </p>
                                    <p>
                                        <a href={item.link} className="btn btn-danger p-2" target="_blank" rel="noreferrer">Meghallgatom SoundCloudon</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}