import events from './api/esemenyek.json';

export function Esemenyek(){

    return (
        <div>
            <h2 className="mt-2 text-secondary display-3 text-center fw-bolder">- FELLÉPÉSEIM -</h2>
            <div className="mt-2 ml-5 mr-5">
            <table className="table table-striped text-center border">
                <thead>
                    <tr>
                        <th className="d-none d-md-block" scope="col">#</th>
                        <th scope="col">Dátum</th>
                        <th scope="col">Helyszín</th>
                        <th scope="col">Zenekar</th>
                        <th className="d-none d-md-block" scope="col">Megjegyzés</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        events.map((event)=>(
                          <tr key={event.no}>
                            <td className="p-2 d-none d-md-block">{event.no}</td>
                            <td className="p-2">{event.date.year}-{event.date.month}-{event.date.day}</td>
                            <td className="p-2">{event.location}</td>
                            <td className="p-2">{event.band}</td>
                            <td className="p-2 d-none d-md-block">{event.comment}</td>
                          </tr>  
                        ))
                    }
                </tbody>
            </table>
            </div>
        </div>
    );
}