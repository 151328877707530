import videok from '../api/video';

export function Video(){
    return (
        <div>
            <h2 className="mt-2 text-secondary display-3 text-center fw-bolder">- VIDEÓK -</h2>
            <div className="mt-2 ml-5 mr-5">
                {
                    videok.map((item)=>(
                        <div key={item.id} className="m-3 p-2 border border-secondary border-5 bg-white text-secondary rounded-3">
                            <h3 className="text-danger">{item.title}</h3>
                            <p><iframe width="100%" height="400" src={item.link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></p>
                            <p></p>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}