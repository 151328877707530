import './App.css';
import logo from './design/flod-logo.png';
import { BrowserRouter, NavLink, Routes, Route} from 'react-router-dom';
// import { Kezdolap } from './Kezdolap';
import { Bio } from './Bio';
import { Esemenyek } from './Esemenyek';
import { Munkaim } from './Munkaim';
import { Video } from './refs/Video';
import { Audio } from './refs/Audio';
import { Catalogue } from './refs/Catalogue';

function App() {
  return (
    <div className="container app p-0">
      <header className="pt-2 px-5">
        <div className="row align-items-center">
          <div className="d-none d-md-block col-md-4 text-end">
            <img
              className="img-fluid" 
              src={logo}
              />
          </div>
          <div className="d-sm-block d-md-none col-sm-6">
            <h1 className="display-1">
              <span className="d-none d-sm-block">&#9733;&#9733;&#9733;</span>
              &#60;FLOD&#47;&#62;
              <span className="d-none d-sm-block">&#9733;&#9733;&#9733;</span>
            </h1>
          </div>
          <div 
            className="col-sm-6 col-md-8">
              <h1 className="text-center display-1">
              <span className="d-none d-md-block">&#9733;&#9733;&#9733;</span>
              ZAJÁSZ ÉS WEBIZÉ
              <span className="d-none d-md-block">&#9733;&#9733;&#9733;</span>
              </h1>
          </div>
        </div>
      </header>

      <BrowserRouter>

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark m-3 rounded-3">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-around" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            {/* <NavLink to={'/'}>
              <span className="btn btn-dark fs-3">Kezdőlap</span>
            </NavLink>
            <div className="d-none d-lg-block text-white fs-3 mt-1">&#9733;</div> */}
            <NavLink to={'/'}>
              <span className="btn btn-dark fs-3">Bemutatkozás</span>
            </NavLink>
            <div className="d-none d-lg-block text-white fs-3 mt-1">&#9733;</div>
            <NavLink to={'/esemenyek'}>
              <span className="btn btn-dark fs-3">Események</span>
            </NavLink>
            <div className="d-none d-lg-block text-white fs-3 mt-1">&#9733;</div>
            <NavLink to={'/munkaim'}>
              <span className="btn btn-dark fs-3">Munkáim</span>
            </NavLink>
          </div>
        </div>
      </nav>
      <main className="px-0 px-md-5">
        <Routes>
          {/* <Route path="/" element={<Kezdolap />}/> */}
          <Route path="/" element={<Bio />}/>
          <Route path="/esemenyek" element={<Esemenyek />}/>
          <Route path="/munkaim" element={<Munkaim />}/>
          <Route path="/munkaim/video" element={<Video />}/>
          <Route path="/munkaim/audio" element={<Audio />}/>
          <Route path="/munkaim/katalogus" element={<Catalogue />}/>
        </Routes>
      </main>
      </BrowserRouter>
      <footer className="bg-dark text-white text-center p-2">
          <p>
            <span className="fs-4">Minden jog fenntartva FLOD &copy; 1998 - 2023</span><br />
            <span>E-mail: flod1980@gmail.com | Telefon: +36 20 343 3093</span>
          </p>
      </footer>
    </div>
  );
}

export default App;
