import { NavLink } from 'react-router-dom';
import videok from './api/video';
import audiok from './api/audio';
import cats from './api/cats';

export function Munkaim(){
    const catCoverSrc = "/images/refimages/catalogues/";
    return (
        <div>
            <h2 className="mt-2 text-secondary display-3 text-center fw-bolder">- REFERENCIÁIM -</h2>
            <div className="mt-2 ml-5 mr-5">
                <h3 className="text-decoration-underline">VIDEÓKLIPJEIM</h3>
                {
                    <div className="m-3 p-2 border border-secondary border-5 bg-white text-secondary rounded-3">
                        <h3 className="text-danger">{videok[0].title}</h3>
                        <p><iframe width="100%" height="400" src={videok[0].link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></p>
                        <p>
                            <NavLink to="/munkaim/video">
                                <span className="btn btn-danger">További videók</span>
                            </NavLink>
                        </p>
                    </div>                   
                }
            </div>
            <div className="mt-2 ml-5 mr-5">
                <h3 className="text-decoration-underline">HANGANYAGAIM</h3>
                {
                    <div className="m-3 p-2 border border-secondary border-5 bg-white text-secondary rounded-3">
                        <h3 className="text-danger">{audiok[0].title}</h3>
                        <p>
                            <iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src={audiok[0].sclink}></iframe>
                        </p>
                        <p>
                            <NavLink to="/munkaim/audio">
                                <span className="btn btn-danger">További hanganyagok</span>
                            </NavLink>
                        </p>
                    </div>                   
                }
            </div>
            <div className="mt-2 ml-5 mr-5">
                <h3 className="text-decoration-underline">KATALÓGUSOK</h3>
                {
                    <div className="m-3 p-2 border border-secondary border-5 bg-white text-secondary rounded-3">
                        <h3 className="text-danger">{cats[0].title}</h3>
                        <div className="text-center mb-2">
                            <img
                                className="border rounded" 
                                src={catCoverSrc+cats[0].cover}
                            />
                        </div>
                        <p>
                            <NavLink to="/munkaim/katalogus">
                                <span className="btn btn-danger">További katalógusok</span>
                            </NavLink>
                        </p>
                    </div>                   
                }
            </div>
        </div>
    );
}