import Catalogues from '../api/cats';

export function Catalogue(){
    const coverSrc = "/images/refimages/catalogues/"
    return (
        <div>
            <h2 className="mt-2 text-secondary display-3 text-center fw-bolder">- KATALÓGUSOK -</h2>
            <div className="mt-2 p-sm-0 px-5 ml-5 mr-5 row justify-content-around">
                {
                    Catalogues.map((item)=>(
                        <div 
                            key={item.id} 
                            className="card col-sm-3 m-1 p-1 border-rounded"
                            >
                            <img
                                className="img-fluid" 
                                src={coverSrc+item.cover}
                                alt={item.title}
                                />
                            <h3 className="card-title fs-5 text-center">{item.title}</h3>
                            <br />
                            <a href={item.link} className="btn btn-danger p-2" target="_blank" rel="noreferrer">Megnézem</a>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}