export function Bio(){
    return (
        <div>
            <h2 className="mt-2 text-secondary display-3 text-center fw-bolder">- MAGAMRÓL -</h2>
            <div className="mt-2 ml-5 mr-5">
            <div key="post.id" className="m-3 p-2 border border-secondary border-5 bg-white text-secondary rounded-3">
                <h3>Üdvözöllek az oldalamon!</h3>
                Abrudbányay Tamásnak hívnak, de az internetes garázdanevem: Flod. Egy 1980-as, pécsi születésű, amatőr-rockisten-szerű zenész és hobbi webdesigner vagyok. Jelenleg Pécsett élek párommal és 2016-os évjáratú kisfiammal. A nap nagyrészében egy hatalmas, óriási, gigantikus méretű multinacionális cég alkalmazottja vagyok, mint alkalmazás üzemeltető.<br /><br />
                A flod.hu-t azért hoztam létre, hogy egy helyen gyűjtsem össze a munkásságaimat.<br /><br/> 
                1993 óta foglalkozom zenével. Ez volt az az év, amikor először fogtam gitárt a kezembe és megírtam a legelső számomat (sosem fogom megosztani :)) Az elmúlt huszonX év alatt több formációban is megfordultam, az alábbi három zenekarban frontemberként is tevékenykedtem:<br/>
                <span className="text-danger">– KAPA-ROCK</span> (1994-1997): Soha nem volt hivatalos koncertünk, inkább csak amolyan otthoni gitározgatás volt, nagyon-nagyon béna dalszövegekkel és azzal a pár akkorddal, amit annak idején le tudtam fogni.<br/>
                <span className="text-danger">– RABLÓBANDA</span> (1998-2010): Az eddigi leghosszabb életű zenekarom volt. Sok szép emlék köt hozzá, hiszen meghatározta a huszaséveimet. Eleinte társzerzőkéént, majd 2002-től kizárólagos zene – és szövegíróként tevékenykedtem, a zenekarban. 12 év alatt a kezdeti 3 akkordos punk zenekarból már egy szinte egész profin megszólaló hard rock zenekar vált az RB-ből, de 2010-ben mégis feloszlattam, mert új alapokon akartam továbbfolytatni a zenélést.<br/>
                <span className="text-danger">– DATZ.HU</span> (2010-2013, 2019 -): Kezdetben D.A.T.’Z (ejtsd: diéjtíz) volt a hivatalos nevünk, de az egyszerűség kedvéért kiszedtük a zavaró írásjeleket és elkezdtük magyarosan ejteni. Erre kiderült, hogy ilyen névvel már létezik egy rap zenekar valahol Németország környékén, így mára hivatalosan is DATZ.HU a zenekar neve. Ebben a formációban már jóval kiforrattabb volt a zene és a stílus is más volt egy picit. Sajnos 2013 végére az egész totális érdektelenségbe fulladt és mindenféle hangfelvétel nélkül feloszlott. 2019-ben meguntam, hogy csak fizetem az éves díjakat domain név fenntartásáért, de nem használom semmire, így - mondjuk úgy - feltámasztottam és egy virtuális rock zenekart csináltam belőle. A zenekart jelenleg 4 db kitalált figura alkotja, akik néha-néha előrukkolnak valamivel, ha én is úgy akarom.
                <br/><br/>
                További zenekarok, ahol megfordultam:<br/>
                <span className="text-danger">– ROCKETS</span> (2001): Ritmusgitárosként szálltam be, de sem koncerten nem léptem fel velük, sem pedig lemezen nem játszottam.<br/>
                <span className="text-danger">– NIRHUANA</span> (2008): Egy pár hónapos RABLÓBANDA szünetben (átszervezés) játszottam ritmusgitárosként az őszi pécsi koncerteken.<br/>
                <span className="text-danger">– ROCKOLYA</span> (2014-2015): Csak próbákon vettem részt ritmusgitárosként, de sem koncerten, sem pedig lemezen nem játszottam.<br/>
                <span className="text-danger">– DETTY JUJU</span> (2015-2017): Ez a formáció nagyban eltért a korábbiaktól, mert a zenekart lényegében csak ketten alkottuk Detty-vel (leszámítva néhány sikertelen tagkísérletet). Az én szerepem volt a ritmus, – szóló, – és basszusgitár témák kidolgozása és feljátszása, valamint a dob leprogramozása.
                <br/><br/>
                Weboldalak készítésével 2001 óta foglalkozom. Elsődleges célom az volt, hogy a RABLÓBANDA zenekar ingyen megjelenhessen a neten (akkor még ingyenes tárhelyeken futott az oldal és nem volt még saját domain sem). Rövid időn belül a hobbimá vált és 2009-től már nem csak saját célra készítek weboldalakat.
            </div>

            </div>
        </div>
    );
}